import { Helmet } from 'react-helmet'
import Layout from '../components/MainLayout'
// import { Link } from 'gatsby'
import React from 'react'
import classNames from 'classnames'
import styles from './pages.module.css'

export default function Home(props) {
  const title = 'Главная'

  return (
    <Layout>
      <Helmet>
        <title>BuyMyCar - {title}</title>
        <meta name="description" content={title} />
      </Helmet>
      <div className={classNames('container', styles.content)}>
        <h2 id="buy">Купим Ваш автомобиль</h2>
        <p>Если Вы не хотите или не знаете как продать автомобиль, у вас нет времени самим заниматься продажей своего авто - обращайтесь к нам.</p>
        <p>
          Наша компания предоставляет услуги по срочному выкупу автомобилей у физических и юридических лиц. Мы работаем на автомобильном рынке уже более 10 лет.
          Выкупаем автомобили в любом состоянии быстро и дорого, выгоднее, чем в trade-in и во многих других компаниях.
        </p>
        <p>
          Все что Вам нужно это оставить заявку на сайте и с Вами свяжется наш специалист, который проведет предварительную оценку вашего авто. Если стоимость
          устроит к Вам подъедет специалист-оценщик, осмотрит автомобиль и Вы на месте заключите договор купли продажи, получите полную стоимость за ваш
          автомобиль наличным или безналичным расчетом.
        </p>

        <h2 id="contacts">Наши контакты</h2>
        <p>
          Телефон: 8 (930) 976-44-92
          <br />
          Адрес: г. Москва, ул. Маршала Воробьева, д.14, к. 2
        </p>
        <div
          dangerouslySetInnerHTML={{
            __html: `
<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A559ed6c257e43f72614b758aa890ca90047062eb0f23c8006b9a4da859ac4820&amp;source=constructor" width="100%" height="500" frameborder="0"></iframe>
        `
          }}
        />
      </div>
    </Layout>
  )
}
